.text {
    font-size: 2.4vh;
    max-width: 100%;
    padding: 7vh;
    color: white;
}

.logo {
    color: white;
}

.btn-circle.btn-xl {
    width: 44vh;
    height: 44vh;
    padding: 7vh 10vh;
    font-size: 5vh;
    line-height: 1.5;
    border-radius: 25vh;
    border-color: aquamarine;
    border-width: 1vh;
}

.menu {
    border-bottom: 2px solid white;
}

.action {
    text-align: center;
}

.message {
    background-color: #25384d;
    color: white;
    font-family: cursive;
}